import React, { useState } from "react";
import PropTypes from "prop-types";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from "react-intl";
import styled from "styled-components/macro";
import { useRollbar } from "@rollbar/react";
import { lighten } from "polished";
import { ApiClient } from "../../utils/Api";

import { bookingSuccess } from "../../store/actions/bookings";
import { Row, Col, Heading5, Icon, Loading, Button, Hidden } from "../";
import { SummaryWrapper, CancelLink, LoadingContainer, ActionBar } from "../Booking/Booking";
import Summary from "../Booking/Summary";
import PayPalButton from "../PaypalButton";
import stripeLogo from "../../assets/powered-by-stripe.svg";
import { COLOR_VARIATION } from "../../theme";
import { useDispatch } from "react-redux";
import DirectPay from "./DirectPay";
import { PAYMENT_TYPES } from "./CheckoutForm";

const Wrapper = styled.div`
  margin-bottom: 14rem; // Aprox. height of overlaying summary

  @media (${props => props.theme.tabletScreen}) {
    padding-right: 1rem;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  .StripeElement {
    width: 100%;
  }
`;

const Title = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
`;

const StripeLink = styled.a`
  display: flex;
  margin-right: auto;
`;

const TermsLink = styled.p`
  margin-bottom: 0.125rem;
  @media (${props => props.theme.tabletScreen}) {
    margin-bottom: 0.5rem;
  }
`;

const CTA = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.25rem 0 0.5rem;

  button {
    flex: 1;
  }

  ${TermsLink} {
    font-size: ${props => props.theme.fontSizes.tiny};
  }

  @media (${props => props.theme.tabletScreen}) {
    margin-top: 3rem;
    ${TermsLink} {
      display: block;
    }
  }

  // Android Chrome soft keyboard
  @media (max-height: 400px) {
    button {
      margin: 1rem 0 0.25rem;
    }
  }
`;

const CheckOutActions = styled.div`
  display: flex;
  flex-direction: column;
`;

const BorderedCol = styled(Col)`
  border-left: 1px solid ${props => props.theme.gray200};
`;

const ErrorMessage = styled.strong`
  color: ${props => props.theme.danger};
  background-color: ${props => lighten(COLOR_VARIATION * 2, props.theme.danger)};
  padding: 0.5rem 1rem;
  border-radius: ${props => props.theme.borderRadius};
  font-size: ${props => props.theme.fontSizes.small};
  font-weight: normal;
`;

const PayPalWrapper = styled(Wrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem;
  text-align: center;
`;

const PaymentAlternative = styled.section`
  text-align: center;
`;

const Separator = styled.div`
  display: flex;
  margin: 0.75rem 0 0.5rem;
  align-items: center;

  &:after {
    content: "";
    border-top: 1px solid ${props => props.theme.gray200};
    flex: 1;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  @media (${props => props.theme.tabletScreen}) {
    margin: 1rem 0 0.7rem;
  }
`;

const StripeCheckoutForm = ({ booking, onCancel, onSuccess, intl }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const rollbar = useRollbar();

  const user = booking.user;
  const redirectUrl = `${window.location.origin}/${booking.provider.slug}/checkout-redirect/${booking.id}`;
  const elementsOptions = {
    layout: {
      type: "accordion",
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: true,
    },
    defaultValues: {
      billingDetails: {
        name: `${booking.user.firstName} ${booking.user.lastName}`,
        email: booking.user.email,
        address: {
          country: booking.user.address?.country,
          line1: booking.user.address?.street,
          postalCode: booking.user.address?.postalCode,
        },
      },
    },
    fields: {
      billingDetails: {
        email: "auto",
        address: "if_required",
      },
    },
  };

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPreparing, setIsPreparing] = useState(false);

  const onPaymentError = error => {
    rollbar.error("Error submitting payment", error, { booking });

    const errorMessage = intl.formatMessage({ id: `Stripe.errors.${error.code}` });
    window.alert(
      errorMessage.includes("Stripe.errors")
        ? intl.formatMessage({ id: "components.Booking.error.payment" })
        : errorMessage,
    );
    setErrorMessage(errorMessage);
    setIsLoading(false);
  };

  // Handle direct payment like ApplePay or GooglePay
  // TODO: This could be better placed in the booking as it should ease the
  //       checkout to not type in all the infos (needs work in Backend too)
  const onDirectPayment = async () => {
    return await stripe.confirmCardPayment(booking.clientSecret).then(result => {
      setIsLoading(false);
      result.paymentIntent ? onSuccess(result.paymentIntent) : onPaymentError(result.error);
    });
  };

  // If the user requested a Paypal payment, but then cancels the process,
  // we have to make sure that we fall back to a Stripe payment.
  const onPaypalCancel = async () => {
    setIsPreparing(true);
    try {
      const updatedBooking = await new ApiClient().bookingPayment(booking.id, "stripe");
      // The booking with updated payment informations (stripe secret) is returned
      if (updatedBooking) {
        dispatch(bookingSuccess(updatedBooking));
      } else {
        window.alert(intl.formatMessage({ id: "components.Booking.error.paymentChange" }));
      }
    } catch (error) {
      console.error(error);
      rollbar.error("Error validating booking", error, { booking });
      window.alert(intl.formatMessage({ id: "components.Booking.error.paymentChange" }));
    }
    setIsPreparing(false);
  };

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    setErrorMessage(null);

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // // Pre-validate elements form
    const { error: submitError } = await elements.submit();
    if (submitError) {
      onPaymentError(submitError);
      return;
    }

    // Send in snake case, as no auto convert is applied
    const billing_details = {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      phone: user.phone,
      address: {
        city: user.address.city,
        line1: user.address.street,
        postal_code: user.address.postalCode,
        country: user.address.country,
      },
    };

    setIsLoading(true);

    // Create a confirmation token to
    const { error, confirmationToken } = await stripe.createConfirmationToken({
      elements,
      params: {
        payment_method_data: {
          billing_details,
        },
        return_url: redirectUrl,
      },
    });

    if (error) {
      return onPaymentError(error);
    }

    // After confirmation token was successfully created, check the payment method
    // and confirm either server-side or client side depending on async methods.
    if (confirmationToken.payment_method_preview.type === PAYMENT_TYPES.SEPA) {
      onSuccess(confirmationToken);
    } else {
      // confirm payment intent
      const { confirmError, paymentIntent } = await stripe.confirmPayment({
        elements,
        clientSecret: booking.clientSecret, // Optional
        confirmParams: {
          return_url: redirectUrl,
        },
        redirect: "if_required",
      });

      if (confirmError) {
        return onPaymentError(confirmError);
      }
      onSuccess(paymentIntent);
    }
    setIsLoading(false);
    return;
  };

  return (
    <Row>
      {isPreparing && (
        <LoadingContainer>
          <Loading text={<FormattedMessage id="components.Booking.loading.prepare" />} />
        </LoadingContainer>
      )}
      {isLoading && (
        <LoadingContainer>
          <Loading text={<FormattedMessage id="components.Booking.loading.payment" />} />
        </LoadingContainer>
      )}

      <Col count={7}>
        {booking.paypalOrderId ? (
          <PayPalWrapper>
            <FormattedMessage id="components.Booking.checkout.paypalPayment" />
            <br />
            <Button
              type="button"
              color="default"
              glow={false}
              onClick={e => {
                e.preventDefault();
                onPaypalCancel();
              }}
            >
              <Icon name="arrow-left" />
              <FormattedMessage id="actions.back" />
            </Button>
          </PayPalWrapper>
        ) : (
          <Wrapper>
            <Title>
              <Heading5>
                <FormattedMessage id="components.Booking.checkout.title" />
              </Heading5>
              <Hidden size="sm">
                <StripeLink href="https://stripe.com" target="_blank">
                  <img src={stripeLogo} alt="Powered by Stripe" />
                </StripeLink>
              </Hidden>
            </Title>

            <Separator>
              <FormattedMessage id="components.Booking.checkout.choosePaymentMethod" />
            </Separator>

            <Row>
              <PaymentElement options={elementsOptions} />
            </Row>

            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

            <Hidden size="sm" style={{ marginTop: "auto" }}>
              <CancelLink href="#" onClick={onCancel}>
                <FormattedMessage id="actions.cancelBooking" />
              </CancelLink>
            </Hidden>
          </Wrapper>
        )}
      </Col>
      <BorderedCol count={5}>
        <SummaryWrapper>
          <Summary
            participants={booking.participants}
            costs={booking.costs}
            serviceFee={booking.serviceFee}
            voucher={booking.voucher}
            priceOption={booking.priceOption}
            course={booking.course}
            event={booking.event}
            provider={booking.provider}
          />
          <CTA>
            <TermsLink as="div">
              <TermsLink>
                {booking.provider.hasTerms ? (
                  <FormattedHTMLMessage
                    id="components.Booking.termsProvider"
                    values={{
                      name: booking.provider.name,
                      slug: booking.provider.slug,
                    }}
                  />
                ) : (
                  <FormattedHTMLMessage id="components.Booking.terms" />
                )}
              </TermsLink>
              <TermsLink>
                {booking.provider.hasPrivacyPolicy ? (
                  <FormattedHTMLMessage
                    id="components.Booking.privacyPolicyProvider"
                    values={{
                      name: booking.provider.name,
                      slug: booking.provider.slug,
                    }}
                  />
                ) : (
                  <FormattedHTMLMessage id="components.Booking.privacyPolicy" />
                )}
              </TermsLink>
            </TermsLink>

            <CheckOutActions>
              {booking.paypalEnabled && !isLoading && (
                <PaymentAlternative>
                  <PayPalButton
                    booking={booking}
                    onSuccess={onSuccess}
                    onCancel={onPaypalCancel}
                    clientId={booking.provider.paypalClientId}
                  />
                  <FormattedMessage id="or" />
                </PaymentAlternative>
              )}
              <DirectPay booking={booking} onPay={onDirectPayment} />
              <ActionBar>
                <Hidden size="md, lg">
                  <Button
                    type="button"
                    color="default"
                    glow={false}
                    onClick={e => {
                      e.preventDefault();
                      onCancel();
                    }}
                    style={{ flex: 0, marginRight: "0.5rem" }}
                  >
                    <Icon name="xmark" />
                  </Button>
                </Hidden>
                <Button
                  type="submit"
                  color="success"
                  glow
                  busy={isLoading}
                  onClick={handleSubmit}
                  disabled={!stripe}
                >
                  <FormattedMessage id="actions.pay" />
                </Button>
              </ActionBar>
            </CheckOutActions>
          </CTA>
        </SummaryWrapper>
      </BorderedCol>
    </Row>
  );
};

StripeCheckoutForm.propTypes = {
  booking: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default injectIntl(StripeCheckoutForm);
